<template>

  <div class="page">
    <TopBarView style="position: relative;z-index: 4;" @about-click="scrollToAppIntroduce"
    @product-click="scrollToFeatureIntroduce" @honor-click="scrollToFeatureHonor"> </TopBarView>
    <div class="head_box flex-col">
      <span class="text_1">看看云医APP</span>
      <span class="text_2">健康服务、轻松云医</span>
      <div class="bt_box flex-row justify-between">
        <div class="text-wrapper_1 flex-col" @click="scrollToAppIntroduce">
          <span class="text_3">APP简介</span>
        </div>
        <div class="text-wrapper_2 flex-col" @click="scrollToFeatureIntroduce">
          <span class="text_4">功能介绍</span>
        </div>
      </div>
      <div class="box_14 flex-row justify-between">
        <div class="text-group_7 flex-col justify-between">
          <span class="text_5">90k&nbsp;+</span>
          <span class="text_6">Users</span>
        </div>
        <div class="text-group_8 flex-col justify-between">
          <span class="text_7">32k&nbsp;+</span>
          <span class="text_8">Daily&nbsp;active</span>
        </div>
        <div class="text-wrapper_13 flex-col justify-between">
          <span class="text_9">2h&nbsp;+</span>
          <span class="text_10">Time&nbsp;spent&nbsp;online</span>
        </div>
      </div>
      <img class="image_1" referrerpolicy="no-referrer" src="../assets/img/img_app.webp" />
    </div>
    <div class="app_introduce_box flex-row" ref="app_introduce">
      <div class="text-wrapper_14 flex-col ">
        <span class="text_31">Introduction</span>
        <span class="text_32">APP简介</span>
      </div>
      <div class="block_7 flex-col"></div>
      <span
        class="paragraph_3">看看云医是一款集医疗资讯、健康咨询和健康知识学习等多功能于一体的手机应用。通过这款应用，用户可以方便地进行账号管理、查询医院及科室信息、获取医生介绍、享受智能导诊服务，并接收重要消息通知。<br /><br />看看云医还提供在线问诊、药品购买以及丰富的健康科普内容，帮助患者在家中轻松获得专业医疗服务，实现高效便捷的就医体验。无论是了解最新的健康资讯，还是在线咨询医生，看看云医都致力于让每一位用户的健康管理变得简单和高效。<br /><br />看看云医都将成为您的贴心健康管家。</span>
    </div>

    <div class="box_3 flex-col" ref="featrue_introduce">
      <div class="section_1 flex-row justify-between">
        <div class="block_11 flex-col justify-between">
          <div class="block_12 flex-row">
            <div class="group_2 flex-col"></div>
            <span class="text_11">Features</span>
          </div>
          <span class="text_12">功能介绍</span>
        </div>
        <span class="text_13">通过整合多种功能，实现了医患之间的高效在线互动，极大地提升了用户的就医体验。从健康知识学习到在线问诊，再到药品购买，看看云医全方位满足用户的健康管理需求，</span>
      </div>
      <div class="box_5 flex-col">
        <div class="list_3" >
          <div style="align-items: center; display: flex;" v-for="(item, index) in loopData0" :key="index"> 

        
          <div v-if="!item.isDiver" class="text-group_9 flex-col" >
            
            <span  class="text_14" v-html="item.text2"></span>
            <span  class="text_16" v-html="item.text1"></span>
            <img  class="thumbnail_1" referrerpolicy="no-referrer" src="../assets/img/ic_down.png" />
            
            <div  class="block_4 flex-col">
              <span class="paragraph_1" v-html="item.text3"></span>
            </div>

            <div  class="text-wrapper_4 flex-col">
            <span class="text_20" v-html="item.text4"></span>
          </div>
       
          </div>
          <div v-if="item.isDiver"  style="display: flex; flex-direction: column; align-items: center;">
            <span  class="text_17" v-html="item.diver"></span>
          </div>
        </div>
        </div>

      </div>
      <div class="section_2 flex-row justify-between">
        <div class="group_15 flex-col">
          <div class="section_3 flex-row">
            <div class="group_5 flex-col"></div>
            <span class="text_23">Partial&nbsp;pages</span>
          </div>
          <span class="text_24">APP页面</span>
          <div class="group_6 flex-col">
          </div>
        </div>
        <div class="group_7 flex-col">

        </div>
        <div class="group_8 flex-col">
        </div>
      </div>
    </div>


   <div ref="featrue_honor"></div>

   <HonorView ></HonorView>

 <FootView></FootView>


  </div>

</template>

<script>
import TopBarView from '@/components/TopBarView.vue';
import HonorView from '@/components/HonorView.vue';
import FootView from '@/components/FootView.vue';
export default {
  components: {
    TopBarView,
    HonorView,
    FootView
  },
  data() {
    return {
      loopData0: [
        {
          isDiver:false,
          text1: 'Online&nbsp;consultation',
          text2:'在线问诊',
          text3:'文字<br />语音<br />视频',
          text4:'支持文字、语音、视频多种形式的在线问诊，让用户随时随地与医生沟通。',
        },
        {
          isDiver:true,
          diver: '&amp;',
        },
        {
          isDiver:false,
          text1: 'Buy&nbsp;medicine&nbsp;online',
          text2:'购药服务',
          text3:'处方药<br />非处方药',
          text4:'集成线上药房，用户可以便捷地购买处方药和非处方药，享受送药上门服务。',

        },
        {
          isDiver:true,
          diver: '&amp;',
        },
        { 
          isDiver:false,
          text1: 'Intelligent&nbsp;consultation',
          text2:'智能导诊',
          text3:'症状自查',
          text4:'通过症状自查和问诊系统，智能推荐合适的科室和医生，提高就医效率',
      
      },
      ],
      constants: {},
    };
  },
  methods: {
    scrollToAppIntroduce() {
      const app_introduce = this.$refs.app_introduce;
      if (app_introduce) {
        app_introduce.scrollIntoView({ behavior: 'smooth' });
      }
    },
    scrollToFeatureIntroduce() {
      const featrue_introduce = this.$refs.featrue_introduce;
      if (featrue_introduce) {
        featrue_introduce.scrollIntoView({ behavior: 'smooth' });
      }
    },
    scrollToFeatureHonor() {
      const featrue_honor = this.$refs.featrue_honor;
      if (featrue_honor) {
        featrue_honor.scrollIntoView({ behavior: 'smooth' });
      }
    },

  },
};
</script>

<style scoped>
.bg_root {
    background-color: #0A294D;
    display: flex;
    flex-direction: column;
}

.menu-item {
    display: inline-block;
    font-size: 20px;
    white-space: nowrap;
    color: white;
    opacity: 0.7;
    text-decoration: none;
}

.menu-bar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 47px;
    padding-bottom: 47px;
    top: 0px;
    right: 0px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.14);
}
</style>
