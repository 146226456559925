<template>
    <div class="bg-root">
        <img style="width: 200px;text-align: center;" src="../assets/img/img_honor_title.png">
        <span style="color:#DBDBDB;font-size: 30px;text-align: center; margin-top: 25px;">CERTIFICATE HONOR</span>


        <div style="width: 100%;display: flex;flex-direction: row; margin-top: 100px;">
            <div style="width: 20%;display: flex;justify-content: center;align-items: center;">

                <img src="../assets/icon/ic_pre_banner.png" style="width: 70px;" @click="per_banner">

            </div>

            <el-carousel :interval="4000" type="card" arrow="never" indicator-position="none" style="width: 60%;"
                ref="banner">
                <el-carousel-item v-for="item in honorUrls" :key="item"
                    style="height: auto;;background-color: white; border-radius: 20px; display: flex; align-items: center;justify-content: center;">
                    <!-- <img style="width: 90%;aspect-ratio: 1.49;margin-top: 5%;margin-bottom: 5%;" :src="item" /> -->

                    <el-image style="width: 90%;aspect-ratio: 1.49;margin-top: 5%;margin-bottom: 5%;" :src="item"
                        :preview-src-list="honorUrls" :preview-teleported="true" />

                </el-carousel-item>
            </el-carousel>

            <div style="width: 20%;display: flex;justify-content: center;align-items: center;">

                <img src="../assets/icon/ic_next_banner.png" style="width: 70px;" @click="next_banner">

            </div>

        </div>

    </div>
</template>


<script setup>

import { ref } from 'vue';
import honor1 from '../assets/img/img_honor1.webp'
import honor2 from '../assets/img/img_honor2.webp'
import honor3 from '../assets/img/img_honor3.webp'
import honor4 from '../assets/img/img_honor4.webp'


const banner = ref(null);

const honorUrls = [
    honor1,
    honor2,
    honor3,
    honor4
];


const per_banner = () => {
    banner.value.prev();
};

const next_banner = () => {
    banner.value.next();
}



</script>

<style scoped>
.bg-root {
    background-image: url('../assets/img/bg_honor.webp');
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 150px;
    padding-right: 150px;
    padding-top: 90px;
    padding-bottom: 170px;
}
</style>