<template>
 <HomePage></HomePage>
</template>

<script>
import HomePage from './components/HomePage.vue'

export default {
  name: 'App',
  components: {
    HomePage
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  padding: 0px;
  /* margin: -8px -8px auto; */
}
</style>
